import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.jsx";

import { readState } from "@/__main__/app-state.mjs";
import blitzMessage, { EVENTS, handleMessage } from "@/__main__/ipc-core.mjs";
import { appURLs } from "@/app/constants.mjs";
import { setOverlaySettings } from "@/game-tft/actions.mjs";
import { TFT_OVERLAY_SETTINGS } from "@/game-tft/definition.mts";
import { Slider, translations } from "@/game-tft/InGameSettingsModal.jsx";

const VALID_SLIDERS = ["size", "opacity", "gamesFromHours"];

function setSettings(settings, overlaySettings, overlayKey) {
  if (overlaySettings && Object.keys(overlaySettings).length) {
    setOverlaySettings({ ...overlaySettings, [overlayKey]: settings }, true);
  }
}

export const comps = [
  {
    text: ["", ""],
    items: [
      {
        select: {
          key: ["compositionsOverlay", "compactMode"],
          options: [
            {
              value: true,
              image: `${appURLs.CDN}/blitz/tft/overlays/tft-comps-simple.webp`,
              text: [
                "overlays:lol.junglePathing.options.overlayStyle.simple",
                "Simple",
              ],
            },
            {
              value: false,
              image: `${appURLs.CDN}/blitz/tft/overlays/tft-comps-detailed.webp`,
              text: [
                "overlays:lol.benchmarking.options.csPerMin.detailed",
                "Detailed",
              ],
            },
          ],
        },
      },
    ],
  },
  () => <RenderSettingsUI overlayKey="compositionsOverlay" />,
];

export const benchmarking = [
  () => <RenderSettingsUI overlayKey="benchmarkingOverlay" />,
];

export const championInfo = [
  () => <RenderSettingsUI overlayKey="championInfoOverlay" />,
];

export const itemCombinations = [
  () => <RenderSettingsUI overlayKey="itemCombinationsOverlay" />,
];

export const onToggle = (overlay, enabled) => {
  const overlaySettingsData = readState.tft.overlaysInGame;
  let overlaySettings = readState.settings.tft.overlays;
  if (overlaySettingsData && Object.keys(overlaySettingsData).length)
    overlaySettings = overlaySettingsData;
  setOverlaySettings({ ...overlaySettings, [overlay.key[0]]: enabled }, true);
};

const RenderSettingsUI = ({ overlayKey }) => {
  const { t } = useTranslation();
  useEffect(() => {
    blitzMessage(EVENTS.GET_TFT_OVERLAY_SETTINGS);
  }, []);

  const overlaySettingsData = readState.tft.overlaysInGame;
  let overlaySettings = readState.settings.tft.overlays;
  if (overlaySettingsData && Object.keys(overlaySettingsData).length)
    overlaySettings = overlaySettingsData;

  const [updatedSettings, setUpdatedSettings] = useState(
    TFT_OVERLAY_SETTINGS[overlayKey],
  );
  handleMessage(EVENTS.TFT_OVERLAY_SETTINGS, (data) => {
    setUpdatedSettings(data[overlayKey]);
  });
  const subToggleSwitches = updatedSettings && Object.entries(updatedSettings);

  const onChange = (key) => (value) => {
    if (VALID_SLIDERS.includes(key)) {
      const newSettings = {
        ...updatedSettings,
        [key]: { ...updatedSettings[key], value: value },
      };
      setUpdatedSettings(newSettings);
      setSettings(newSettings, overlaySettings, overlayKey);
    } else {
      const newSettings = {
        ...updatedSettings,
        [key]: value,
      };
      setUpdatedSettings(newSettings);
      setSettings(newSettings, overlaySettings, overlayKey);
    }
  };

  return subToggleSwitches?.length
    ? subToggleSwitches.map(([key, value], idx) => {
        const translation = translations[key];
        if (!translation) return null;
        if (typeof value === "object") {
          return (
            <div key={idx} className="flex column gap-1">
              <div className="title">{t(...translation)}</div>
              <Slider
                min={value.min}
                max={value.max}
                step={value.step}
                defaultValue={value.value}
                onChange={onChange(key)}
              />
            </div>
          );
        }
        return (
          <div
            key={idx}
            className="flex justify-between gap-2 align-center wrap"
          >
            <div className="title">{t(...translation)}</div>
            <ToggleSwitch value={value} onChange={onChange(key)} />
          </div>
        );
      })
    : null;
};
